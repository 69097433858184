import axios from 'axios';
import { CORE_SERVER_HOST } from '../../../configs';

export default async function getCreator(creatorId: string) {
  try {
    const { data } = await axios.get(
      `https://${CORE_SERVER_HOST}/api/creators/${creatorId}`
    );

    return data;
  } catch (e) {
    console.error(e);
  }
}
