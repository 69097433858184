import { Link, navigate, PageProps } from 'gatsby';
import React, { useState } from 'react';
import Divider from '../../../components/Divider';
import Layout from '../../../components/Layout';
import NavigationBar from '../../../components/NavigationBar';
import BeltBanner from '../../../components/BeltBanner';
import { displaySmall } from '../../../styles';
import { css } from '@emotion/react';
import GameListItem from '../../../components/GameListItem';
import EmptyGameList from '../../../components/EmptyGameList';
import styled from '@emotion/styled';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Tab } from '../../../components/MenuTap/Tab';

import Profile from '../../../components/Profile/Profile';
import qs from 'qs';
import { ScrollRefetchingContainer } from '../../../components/ScrollRefetchingContainer';
import PullToRefresh from 'react-simple-pull-to-refresh';
import useSWR, { mutate } from 'swr';
import { FeedType } from '../../../models/feedType';
import { changeEnableScroll } from '../../../utils/bridge';
import { useCoreApi } from '../../../apis/core';
import { swrDefaultOption } from '../../../utils/swr';
import { getLocalStorage } from '../../../utils/localStorage';
import { LocalStorageKey } from '../../../models/localStorage';
import { getQueryString } from '../../../utils/queryString';
import { decodeJwt } from 'jose';
import { getNewAccessToken } from '../../../utils/accessToken';
import getCreator from '../../../apis/core/getCreator';

interface IndexProps {
  location: PageProps['location'];
  params: {
    creatorId: string;
  };
}

export default function Index({ params, location }: IndexProps) {
  const creatorId = params['creatorId'];
  const coreApi = useCoreApi();
  const accessTokenRef = React.useRef(
    getLocalStorage(LocalStorageKey.ACCESS_TOKEN)
  );
  const feedId = getQueryString('feedId');
  const feedType = getQueryString('feedType');
  const directMode = getQueryString('direct');

  React.useEffect(() => {
    changeEnableScroll(false);
  }, []);

  async function sendGetCreatorFollowByChannelId() {
    return coreApi.getCreatorFollowByChannelId({
      accessToken: accessTokenRef.current || '',
      channelId: creatorId,
    });
  }

  const { data: creator } = useSWR(
    'getCreator',
    () => getCreator(creatorId),
    swrDefaultOption
  );

  const {
    data: creatorFollowData,
    isLoading: isLoadingCreatorFollowData,
    mutate: mutateCreatorFollowData,
  } = useSWR(
    'getCreatorFollowByChannelIdInCreatorMain',
    sendGetCreatorFollowByChannelId,
    swrDefaultOption
  );

  const handleRefresh = React.useCallback(async () => {
    await Promise.all([
      mutate(
        `/api/${creatorId}/community/creatorFeeds?${qs.stringify({
          isPin: true,
        })}`
      ),
      mutate(['mutateContentSectionFeeds', creatorId, FeedType.USER]),
      mutate(['mutateContentSectionFeeds', creatorId, FeedType.CREATOR]),
      mutateCreatorFollowData(),
    ]);
  }, [creatorId, mutateCreatorFollowData]);

  React.useEffect(() => {
    if (
      !isLoadingCreatorFollowData &&
      directMode &&
      typeof window !== 'undefined'
    ) {
      navigate(window.location.pathname, { replace: true });
      // 강제로 interval을 줘서 라우팅이 안꼬이게 수정
      setTimeout(() => {
        navigate(
          `/feedDetailView?${qs.stringify({
            feedId,
            feedType,
            channelId: creatorId,
          })}`,
          {
            state: {
              previousPathname: window.location.pathname,
            },
          }
        );
      }, 100);
    }
  }, [isLoadingCreatorFollowData]);

  const accessTokenRefreshTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const runAutoRefreshToken = (prevToken: string) => {
    const { exp } = decodeJwt(prevToken);

    if (!exp) return;
    accessTokenRefreshTimeoutRef.current = setTimeout(() => {
      window.Realworld.refreshToken();
      getNewAccessToken(prevToken).then((nextToken) => {
        if (nextToken) {
          accessTokenRef.current = nextToken;
          runAutoRefreshToken(nextToken);
        }
      });
    }, Math.max(exp * 1000 - Date.now() - 1000 * 60 * 1, 0));
  };

  React.useEffect(() => {
    const isIframe = window.self !== window.top;
    const prevToken = getLocalStorage(LocalStorageKey.ACCESS_TOKEN);

    if (!prevToken) return;
    if (isIframe) return;

    runAutoRefreshToken(prevToken);

    return () => {
      if (accessTokenRefreshTimeoutRef.current) {
        clearTimeout(accessTokenRefreshTimeoutRef.current);
        accessTokenRefreshTimeoutRef.current === undefined;
      }
    };
  }, []);

  // 리얼월드 홈페이지에 스탬프 페이지를 iframe으로 추가하면서 필요한 로직
  // 메시지 보내는 코드는 realworld-homepage 레포지토리를 확인해주세요.
  React.useEffect(() => {
    const isIframe = window.self !== window.top;
    if (!isIframe) return;

    const handleMessage = (e: MessageEvent) => {
      if (e.data.type === 'accessToken') {
        // 홈페이지에서 전달한 이벤트 객체의 data 속성에 accessToken이 담겨 있음.
        window.localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, e.data.data);
        accessTokenRef.current = e.data.data;
      }
    };

    window.addEventListener('message', handleMessage);
    window.parent.postMessage('addEventListener', '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Layout>
      <NavigationBar>크리에이터 홈</NavigationBar>
      <BeltBanner appId={creatorId} />
      {creator ? (
        <PullToRefresh
          css={scrollAreaStyle}
          onRefresh={handleRefresh}
          pullingContent={<ScrollRefetchingContainer isRotating={false} />}
          refreshingContent={<ScrollRefetchingContainer isRotating={true} />}
          pullDownThreshold={100}
          maxPullDownDistance={120}
        >
          <>
            <Profile
              creator={creator}
              accessTokenRef={accessTokenRef}
              creatorFollowData={creatorFollowData}
              isLoadingCreatorFollowData={isLoadingCreatorFollowData}
              mutateCreatorFollowData={mutateCreatorFollowData}
            />
            <Divider />
            <TabList
              creator={creator}
              location={location}
              accessTokenRef={accessTokenRef}
              creatorFollowData={creatorFollowData}
            />
          </>
        </PullToRefresh>
      ) : (
        <LoadingSpinner />
      )}
    </Layout>
  );
}

const scrollAreaStyle = css`
  overflow-y: auto;
  flex: 1;
`;

const tabMenuList = [
  {
    index: 0,
    name: '홈',
    contents: 'home',
  },
  {
    index: 1,
    name: '피드',
    contents: 'feed',
  },
  {
    index: 2,
    name: '커뮤니티',
    contents: 'community',
  },
];

interface TabListProps {
  creator: Creator;
  location: PageProps['location'];
  creatorFollowData?: {
    isFollowed: boolean;
  };
  accessTokenRef: React.MutableRefObject<string | undefined>;
}

function TabList({
  creator,
  location,
  creatorFollowData,
  accessTokenRef,
}: TabListProps) {
  const searchParams =
    typeof window === 'undefined'
      ? undefined
      : new URLSearchParams(window.location.search);
  const prevTab = searchParams?.get('tab');
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    prevTab ? parseInt(prevTab) : 0
  );
  const tabListData = tabMenuList[selectedTabIndex];

  const tabComponentList = tabMenuList.map((tab, index) => {
    return (
      <Tab
        creator={creator}
        key={tab.index}
        contents={tabListData.contents}
        style={{ display: index !== selectedTabIndex ? 'none' : '' }}
        creatorFollowData={creatorFollowData}
        accessTokenRef={accessTokenRef}
      />
    );
  });
  return (
    <TabsContainer>
      <ul className="tabs-ul">
        {tabMenuList.map((tab, index) => {
          return (
            <li
              onClick={() => {
                setSelectedTabIndex(index),
                  navigate(`?${qs.stringify({ tab: index })}`, {
                    replace: true,
                  });
              }}
              className={index === selectedTabIndex ? 'clicked btn' : 'btn'}
              key={tab.index}
            >
              {tab.name}
            </li>
          );
        })}
      </ul>
      {tabComponentList}
      {tabListData.contents === 'home' && (
        <GameListPreview
          appId={creator.id}
          projects={creator.projects}
          location={location}
        />
      )}
    </TabsContainer>
  );
}

const TabsContainer = styled.div`
  width: 100%;
  background-color: #ffffff;

  & > ul {
    border-bottom: 1px solid #ededed;
    height: auto;
    list-style: none;
    display: flex;
  }
  > li {
    width: 33px;
  }

  .btn {
    color: #a9a9a9;
    border-radius: 0px;
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    margin-left: 16px;
    padding: 8px 7px;
    border: 0;
  }
  .clicked {
    color: #000000;
    border-bottom: 3px solid #d07fff;
    font-weight: bold;
    margin-bottom: -1px;
    padding-bottom: 7px;
  }
`;

interface GameListPreviewProps {
  projects: ProjectLite[];
  appId: string;
  location: PageProps['location'];
}

function GameListPreview({ projects, appId, location }: GameListPreviewProps) {
  return (
    <section
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <header
        css={css`
          display: flex;
          justify-content: space-between;
          margin: var(--xlarge2) var(--large) var(--xlarge);
        `}
      >
        <h2 css={displaySmall}>만든 게임</h2>
        {projects && projects.length > 0 && (
          <Link
            to={`/creators/${appId}/games/`}
            onClick={() => {
              gtag('event', 'click_showAllProject_inCreatorHome', {
                userID: window.Realworld.getUserId(),
                creatorName_creatorID: appId,
              });
            }}
            state={{
              previousPathname: location.pathname,
            }}
            css={css`
              font-style: normal;
              font-weight: 500;
              font-size: 1.75rem;
              line-height: var(--xlarge);
              color: #282f89;
            `}
          >
            전체보기
          </Link>
        )}
      </header>
      <ul
        css={css`
          display: flex;
          overflow-x: auto;
          overflow-y: hidden;
          list-style: none;

          &::-webkit-scrollbar {
            display: none;
          }

          > li {
            &:first-of-type {
              margin-left: var(--medium);
            }
            margin-right: var(--medium);
          }
        `}
      >
        {projects &&
          projects.slice(0, 20).map((project, index) => (
            <li key={project.id}>
              <GameListItem type="horizon" game={project} index={index} />
            </li>
          ))}
      </ul>
      {projects && projects.length === 0 && (
        <EmptyGameList
          css={css`
            margin-bottom: 8.5rem;
          `}
        />
      )}
    </section>
  );
}
